import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import barba from '@barba/core';
import { gsap } from "gsap";
Swiper.use([Navigation, Pagination]);

function initSwiper(selector, controller) {
    return new Swiper(selector, {
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        controller: {
            control: controller,
        },
    });
}

let swiperLeft = initSwiper('.swiper-left');
let swiperRight = initSwiper('.swiper-right', swiperLeft); // Pass the swiperLeft instance as the controller for swiperRight
initSwiper('.swiper');

barba.init({
    transitions: [{
        name: 'project',
        from: {
            namespace: [
                'project'
            ]
        },
        to: {
            namespace: [
                'project'
            ]
        },
        leave(data) {
            const tl = gsap.timeline();
            tl.to(data.current.container.querySelectorAll('.title-word'), {
                y: '100%',
                stagger: 0.07,
                ease: 'slowMo.out',
                opacity: 0,
                duration: 0.2,
            })
                .to(data.current.container.querySelectorAll('.infos'), {
                    y: "100%",
                    stagger: 0.07,
                    duration: 0.2,
                    opacity: 0,
                }, "-=0.3")

            return tl;

        },
    }],
    views: [{
        namespace: 'project',
        afterEnter(data) {
            gsap.set('.title-word', {
                opacity: 1,
            });
            gsap.to('.title-word', {
                y: 0,
                stagger: 0.07,
                duration: 0.3,
                ease: 'slowMo.out',
                opacity: 1,
            });
            gsap.to('.line', {
                stagger: 0.07,
                duration: 0.2,
                ease: 'slowMo.out',
                scaleX: 1,
            });
            gsap.to('.infos', {
                stagger: 0.07,
                duration: 0.5,
                opacity: 1,
                y: 0,
            });
            swiper = initSwiper('.swiper');
        }
    }, {
        namespace: 'projects',
        afterEnter(data) {
            gsap.to('.project-title', {
                delay: 0.2,
                opacity: 1,
                duration: 0.1,
            });
            gsap.to('.project-title', {
                y: 0,
                ease: 'slowMo',
                stagger: 0.08,
            });
            gsap.to('.line', {
                scaleX: 1,
                ease: 'slowMo',
                stagger: 0.08,
            });
        }
    },
    {
        namespace: 'default',
        afterEnter(data) {
            document.querySelectorAll('.editor').forEach(editor => {
                gsap.to(editor.children, {
                    opacity: 1,
                    stagger: 0.06,
                    duration: 0.3
                })
            })
        }
    }, {
        namespace: 'gallery',
        afterEnter(data) {
            swiperLeft = initSwiper('.swiper-left');
            swiperRight = initSwiper('.swiper-right', swiperLeft); //
            swiperLeft.controller.control = swiperRight;
            swiperRight.controller.control = swiperLeft;
        }
    }],
});



function handleNavigation() {
    const items = document.querySelectorAll('.navigation-item');
    items.forEach(item => {
        item.addEventListener('mouseover', function () {
            this.style.backgroundColor = this.getAttribute('data-text-color');
            this.style.color = this.getAttribute('data-main-color');
        });

        item.addEventListener('mouseout', function () {
            if (!this.classList.contains('active')) {
                this.style.backgroundColor = ''; // Reset aux styles par défaut
                this.style.color = ''; // Reset aux styles par défaut
            } else {
                this.style.backgroundColor = this.getAttribute('data-text-color');
                this.style.color = this.getAttribute('data-main-color');
            }
        });

        // Si l'élément est actif, appliquez les styles appropriés.
        if (item.classList.contains('active')) {
            item.style.backgroundColor = item.getAttribute('data-text-color');
            item.style.color = item.getAttribute('data-main-color');
        }
    });
}

document.addEventListener('DOMContentLoaded', function () {
    handleNavigation();
    initSwiper('.swiper');
    if (document.querySelector('.splash-logo')) {
        let mm = gsap.matchMedia();
        var tl = gsap.timeline({ delay: 0.3, })
        mm.add("(max-width: 1024px)", () => {
            tl.set('.gallery', {
                opacity: 0
            });
            tl.to('.logo-letters', {
                y: "200%",
                stagger: {
                    from: "random",
                    amount: 0.4
                }
            });

            tl.to('.splash', {
                duration: 0.4,
                opacity: 0
            });
            tl.set('.splash', {
                display: 'none',
            });
            tl.to('.gallery', {
                opacity: 1
            }, "-=0.5");
        })

        mm.add("(min-width: 1024px)", () => {
            var tld = gsap.timeline({ delay: 0.3, })
            tld.set('.gallery', {
                opacity: 0
            });
            tld.to('.splash-logo ', {
                scale: 2.8,
            });
            tld.to('.logo-letters', {
                y: "200%",
                stagger: {
                    from: "random",
                    amount: 0.4
                }
            });
            tld.to('#logo-1', {
                x: '100%',
            });
            tld.to('#logo-2', {
                x: '-100%',
            }, "-=100%");

            tld.to('.splash', {
                duration: 0.4,
                opacity: 0
            });
            tld.to('.gallery', {
                opacity: 1
            }, "-=0.5");

            tld.to('.splash', {
                duration: 0.4,
                opacity: 0
            });
            tld.set('.splash', {
                display: 'none',
            });
            tld.to('.gallery', {
                opacity: 1
            }, "-=0.5");
        })
    }
});


barba.hooks.after(() => {
    handleNavigation();
    initSwiper('.swiper');
});
